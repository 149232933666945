import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  background-color: #0040ff;
  border-radius: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  outline: none;
  border: 0;
  padding: 16px;
  color: #fff;
  width: 50%;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 1200px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 80%;
  }
`;
