/* eslint-disable react/function-component-definition */
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { Authorize, AuthPages } from 'oidc-havan-ts';
import App from './App';
import { Home } from './Pages/Home';
import { Post } from './Pages/Admin/Post';
import { Posts } from './Pages/Admin/Posts';
import { Admin } from './Pages/Admin';
import { Marketing } from './Pages/Marketing';
import { ContatosMarketings } from './Pages/Admin/ContatosMarketing';
import { ContatoMarketing } from './Pages/Admin/ContatosMarketing/ContatoMarketing';
import { OAuthRedirect } from './Pages/Redirect';

export const MainRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {AuthPages.map(route => (
          <Route {...route} />
        ))}
        <Route
          path="acesse-aqui"
          element={
            <App>
              <Home />
            </App>
          }
        />
        <Route
          path="contatos"
          element={
            <App>
              <Marketing />
            </App>
          }
        />
        <Route
          path="admin/:auth?"
          element={
            <App Autentication>
              <Authorize>
                <Admin />
              </Authorize>
            </App>
          }
        />
        <Route
          path="posts/"
          element={
            <App Autentication>
              <Authorize>
                <Posts />
              </Authorize>
            </App>
          }
        />
        <Route
          path="post/:Id?"
          element={
            <App Autentication>
              <Authorize>
                <Post />
              </Authorize>
            </App>
          }
        />
        <Route
          path="contatos-marketing/"
          element={
            <App Autentication>
              <Authorize>
                <ContatosMarketings />
              </Authorize>
            </App>
          }
        />
        <Route
          path="contato-marketing/:Id?"
          element={
            <App Autentication>
              <Authorize>
                <ContatoMarketing />
              </Authorize>
            </App>
          }
        />
        <Route path="*" element={<Navigate to="acesse-aqui" />} />
        {/* <Route
          path="oauth/:authentication?"
          element={
            <App>
              <OAuthRedirect />
            </App>
          }
        /> */}
      </Routes>
    </BrowserRouter>
  );
};
