import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface IPreview {
  src: string;
}

export const ListContainer = styled.ul`
  list-style-type: none;
`;

export const FileInfo = styled.li`
  display: flex;
  background-color: rgba(207, 207, 207, 0.3);
  margin-top: 10px;
  border-radius: 4px;
`;

export const Preview = styled.div<IPreview>`
  height: 77px;
  width: 77px;
  border-radius: 4px 0 0 4px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%, 50%;
  padding: 5px 10px;
  :hover {
    filter: brightness(70%);
    cursor: pointer;
  }
`;

export const FileData = styled.div`
  display: flex;
  margin-left: 5px;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  padding: 5px 10px;
`;

export const FileName = styled.p`
  font-weight: bold;
`;

export const FileType = styled.span`
  display: flex;
  font-weight: 300;
`;

export const FileActions = styled.div`
  display: flex;
`;

export const ListActions = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column-reverse;
  justify-content: start;
  padding: 2px 5px;
`;

export const LinkFileAction = styled.a`
  text-decoration: none;
  cursor: pointer;
`;

export const ProgressContainer = styled.div`
  width: 50px;
  height: 50px;
  padding: 15px 0;
  margin-right: 10px;
`;
