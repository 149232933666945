/* eslint-disable jsx-a11y/alt-text */
import Grid from '@mui/material/Grid';

interface IImagePreview {
  url: string;
}

export const ImgPreview = ({ url }: IImagePreview) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        marginTop: '16px',
      }}
    >
      <Grid item xs={12}>
        <div className="preview-img" style={{ height: 'auto' }}>
          <img src={url} />
        </div>
      </Grid>
    </Grid>
  );
};
