/* eslint-disable react/function-component-definition */
import TextField from '@mui/material/TextField';
import { useField } from '@unform/core';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface InputProps {
  Name: string;
  Placeholder: string;
  Require?: boolean;
  Label: string;
  FullWidth?: boolean;
  MaxLength?: number;
  DefaultValue?: string;
}

const HvnInput = styled(TextField)`
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  & label.Mui-focused {
    color: #6b7786;
  }
  & .MuiOutlinedInput-root {
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    & fieldset {
      border-color: #6b7786;
      border-radius: 8px;
    }
    &.Mui-focused fieldset {
      border-color: #1b4d9d;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 4px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const TextArea: React.FC<InputProps> = ({
  Label,
  Name,
  Placeholder,
  Require,
  FullWidth,
  MaxLength,
  DefaultValue,
}: InputProps) => {
  const TextAreaRef = useRef<HTMLInputElement>(null);

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(Name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: TextAreaRef.current,
      getValue: ref => {
        return ref.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  const VerifyErr = () => {
    if (error) {
      clearError();
    }
  };

  return (
    <HvnInput
      inputRef={TextAreaRef}
      fullWidth={FullWidth}
      multiline
      variant="outlined"
      required={Require}
      label={Label}
      name={Name}
      placeholder={Placeholder}
      spellCheck
      helperText={error || ''}
      error={!!error}
      onChange={VerifyErr}
      defaultValue={DefaultValue}
      InputLabelProps={{ size: 'small' }}
      inputProps={{ maxLength: MaxLength }}
    />
  );
};
