import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useUser } from 'oidc-havan-ts';
import { Btn } from '../../../../Core/Components/Button';
import { Line } from '../../../../Core/Components/Line';
import { API } from '../../../../Core/services/config-https';
import './index.scss';

interface IAlterarStatus {
  Id: number;
  Status: boolean;
  Close: () => void;
}

export const AlterarStatus = ({ Id, Status, Close }: IAlterarStatus) => {
  const { accessToken } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleInativar = useCallback(async () => {
    API.patch(
      '/Post/AtivarInativar',
      {
        id: Id,
        status: Status,
      },
      {
        headers: { Authorization: accessToken },
      },
    )
      .then(response => {
        const { data: Data } = response;
        if (Data.success) {
          enqueueSnackbar(`Post ${Id} ${Status ? 'Ativado' : 'Inativado'}`, {
            variant: 'success',
          });
          setTimeout(() => {
            window.location.href = '/posts';
          }, 4000);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao Alterar o Status', {
          variant: 'error',
        });
      });
  }, [Id, Status, accessToken, enqueueSnackbar]);

  return (
    <div className="alterarStatus">
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid item xs={12}>
          <p id="AlterarStatusQuestion">
            {Status ? `Ativar o Post ${Id} ? ` : `Inativar o Post ${Id} ?`}
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '32px',
        }}
      >
        <Grid item xs={12}>
          <Line />
        </Grid>
        <Grid item xs={6}>
          <Btn
            Text="Fechar"
            OnClick={() => {
              Close();
            }}
            BtnType="button"
            Type="regular"
            Variant="outlined"
            Size="large"
            Width="100%"
          />
        </Grid>
        <Grid item xs={6}>
          <Btn
            Text={Status ? 'Ativar' : 'Inativar'}
            BtnType="button"
            Type="primary"
            Variant="contained"
            Size="large"
            Width="100%"
            OnClick={handleInativar}
          />
        </Grid>
      </Grid>
    </div>
  );
};
