/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-this-in-sfc */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Dropzone, { useDropzone } from 'react-dropzone';
import { DropContainer, UploadMensage } from './style';

export const Drop = (props: any) => {
  const { onUpload } = props;
  const RenderUploadMenssage = (
    isDragActive: boolean,
    isDragReject: boolean,
  ) => {
    if (!isDragActive) {
      return (
        <UploadMensage>
          <CloudUploadIcon />
          <p>Arraste seus arquivos aqui!</p>
          <small>
            <b>
              <u>Apenas 1 Anexo é permitido </u>
            </b>
          </small>
        </UploadMensage>
      );
    }
    if (isDragReject) {
      return (
        <UploadMensage type="Erro">
          <HighlightOffIcon />
          <p>Arquivo não Suportado</p>
        </UploadMensage>
      );
    }

    return (
      <UploadMensage type="Sucess">
        <CheckCircleOutlineOutlinedIcon />
        <p>Solte seus arquivos</p>
      </UploadMensage>
    );
  };

  const aceitar = {
    'image/*': ['.jpeg', '.png', '.jpg'],
  };

  return (
    <Dropzone accept={aceitar} onDropAccepted={onUpload} maxFiles={1}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {RenderUploadMenssage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};
