import styled from 'styled-components';

interface ITag {
  bgcolor: string;
  color: string;
}

export const TagElement = styled.div<ITag>`
  background-color: ${props => props.bgcolor};
  color: ${props => props.color};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 0 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;
