import { SvgIcon } from '@mui/material';

export const XIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_476_2)">
          <path
            d="M17.3259 1.05664H20.6992L13.3293 10.3767L22 23.0566H15.2112L9.8945 15.3653L3.80967 23.0566H0.4345L8.31783 13.0875L0 1.05765H6.96117L11.7673 8.08776L17.3259 1.05664ZM16.1425 20.8235H18.0116L5.9455 3.17318H3.93983L16.1425 20.8235Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_476_2">
            <rect width="22" height="22" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
