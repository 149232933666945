/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'oidc-havan-ts';
import { BreadCrumbsLinks } from '../../../../Core/Components/BreadCrumbs/types';
import { Btn } from '../../../../Core/Components/Button';
import { Input } from '../../../../Core/Components/Form/Input';
import { InputMask } from '../../../../Core/Components/Form/InputMask';
import { TextArea } from '../../../../Core/Components/Form/TextArea';
import { Menu } from '../../../../Core/Components/Menu';
import { Page } from '../../../../Core/Components/Page';
import { Upload } from '../../../../Core/Components/Upload';
import { useAuth } from '../../../../Core/Context/useAuth';
import { API } from '../../../../Core/services/config-https';
import {
  ContatosMarketingApi,
  InsertContatosMarketing,
  UpdateContatosMarketing,
} from '../../../../Core/Types/Type';
import './index.scss';
import { useValidatePermition } from '../../../../Core/hooks/useValidatePermition';

interface FileProps {
  file: [];
  id: string;
  name: string;
  preview: string;
  downloadURL: string;
  url: string;
  type: string;
  progress: number;
  upload: boolean;
  erro: boolean;
}

export const ContatoMarketing = () => {
  const { Id } = useParams() as any;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [Pesquisando, SetPesquisando] = useState<boolean>(!Id);
  const [Contato, SetContato] = useState<ContatosMarketingApi>();
  const [Anexos, SetAnexos] = useState<Array<FileProps>>([]);
  const FormRef = useRef<FormHandles>(null);
  const { accessToken, identity } = useUser();

  const breadcrumbs: BreadCrumbsLinks = {
    CurrentTitle: Id ? `Contato ${Id}` : 'Cadastrar Contato',
    Pass: [
      {
        Link: '/admin',
        Title: 'Home',
      },
      {
        Link: '/contatos-marketing',
        Title: 'Listar Contatos',
      },
    ],
  };

  const handleSubmit = (data: any) => {
    const anexo = document.getElementsByClassName(
      'anexos-data',
    ) as HTMLCollectionOf<HTMLInputElement>;
    if (
      data.Telefone.replaceAll(' ', '').length !== 14 ||
      data.Telefone[5] !== '9'
    ) {
      enqueueSnackbar('O Campo Telefone é inválido', { variant: 'error' });
    } else if (anexo.length > 0 && anexo.length < 2) {
      if (Id) {
        const BodyData: UpdateContatosMarketing = {
          id: Id,
          nome: data.Nome,
          telefone: data.Telefone,
          texto: data.Texto,
          idAnexoFotoPerfil: Number(anexo[0].value) || 0,
        };

        API.put('/ContatosMarketing', BodyData, {
          headers: { Authorization: accessToken },
        })
          .then(response => {
            if (response.data.success) {
              enqueueSnackbar(`Contato ${response.data.model} Alterado`, {
                variant: 'success',
              });
              setTimeout(() => {
                navigate('/contatos-marketing');
              }, 4000);
            }
          })
          .catch((error: any) => {
            if (error) {
              enqueueSnackbar(error.response.data, {
                variant: 'error',
              });
            } else {
              enqueueSnackbar('Ocoreu um erro ao alterar', {
                variant: 'error',
              });
            }
          });
      } else {
        const BodyData: InsertContatosMarketing = {
          nome: data.Nome,
          telefone: data.Telefone,
          texto: data.Texto,
          status: true,
          idAnexoFotoPerfil: Number(anexo[0].value) || 0,
        };

        API.post('/ContatosMarketing', BodyData, {
          headers: { Authorization: accessToken },
        })
          .then(response => {
            if (response.data.success) {
              enqueueSnackbar(`Contato ${response.data.model} Cadastrado`, {
                variant: 'success',
              });
              setTimeout(() => {
                navigate('/contatos-marketing');
              }, 4000);
            }
          })
          .catch((error: any) => {
            if (error) {
              enqueueSnackbar(error.response.data, {
                variant: 'error',
              });
            } else if (
              error.message === 'Request failed with status code 400'
            ) {
              enqueueSnackbar('Ocoreu um erro ao cadastrar', {
                variant: 'error',
              });
              setTimeout(() => {
                navigate('/contatos-marketing');
              }, 4000);
            } else {
              enqueueSnackbar('Ocoreu um erro ao cadastrar', {
                variant: 'error',
              });
            }
          });
      }
    } else {
      enqueueSnackbar('Um anexo é necessário', { variant: 'error' });
    }
  };

  const GetAnexos = (anexoId: string, anexoNameBlob: string) => {
    SetAnexos([]);
    const files: Array<FileProps> = [];
    files.push({
      id: anexoId,
      upload: true,
      name: `AcesseAqui-${anexoId}`,
      preview: `${process.env.REACT_APP_ANEXO_URL}${anexoNameBlob}`,
      url: `${process.env.REACT_APP_ANEXO_URL}${anexoNameBlob}`,
      downloadURL: '',
      erro: false,
      file: [],
      progress: 100,
      type: '',
    });
    if (files.length > 1) {
      files.shift();
    }
    SetAnexos(files);
  };

  const handleBuscarPost = useCallback(async () => {
    await API.get('/ContatosMarketing', {
      headers: { Authorization: accessToken },
      params: { Id },
    })
      .then(response => {
        const { data: Data } = response;
        SetAnexos([]);
        SetContato(Data.items[0]);
        GetAnexos(Data.items[0].idAnexoFotoPerfil, Data.items[0].anexo);
        setTimeout(() => {
          SetPesquisando(true);
        }, 2000);
      })
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao realizar a busca', {
          variant: 'error',
        });
      });
  }, [Id, accessToken, enqueueSnackbar]);

  useEffect(() => {
    if (accessToken !== null && Id) handleBuscarPost();
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_CONTATOS').then(v => {
        if (!v) {
          navigate('/admin');
        }
      });
    }
  }, [accessToken, identity, handleBuscarPost, navigate]);

  return (
    <Menu>
      <Page title="Contato Marketing" BreadCrumb={breadcrumbs}>
        {Pesquisando ? (
          <Grow in={Id ? Pesquisando : true}>
            <div className="contato-form">
              <Form onSubmit={handleSubmit} ref={FormRef}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    '& > :not(style)': { marginTop: '16px' },
                  }}
                >
                  <Grid item xs={12}>
                    <h2>
                      {Id ? `Alterar Contato ${Id}` : 'Cadastrar Contato'}
                    </h2>
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      Name="Nome"
                      Label="Nome"
                      Placeholder="Nome"
                      Require
                      Width="100%"
                      Type="text"
                      DefaultValue={Id ? Contato?.nome : ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      Name="Telefone"
                      Label="Telefone"
                      Placeholder="Telefone"
                      Width="100%"
                      Mask="(##) # ####-####"
                      Require
                      DefaultValue={Id ? Contato?.telefone : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea
                      Name="Texto"
                      Placeholder="Digite aqui a descrição"
                      Label="Descrição"
                      FullWidth
                      Require
                      MaxLength={500}
                      DefaultValue={Id ? Contato?.texto : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Upload Files={Anexos} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    marginTop: '64px',
                  }}
                >
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Btn
                      BtnType="submit"
                      Variant="contained"
                      Type="primary"
                      Text={Id ? 'Salvar Alterações' : 'Cadastrar'}
                    />
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Grow>
        ) : (
          <Grid container spacing={1} sx={{ marginTop: '5px' }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Page>
    </Menu>
  );
};
