import styled from 'styled-components';

export const Table = styled.table`
  padding: 8px;
  border: 0;
  width: 100%;
  border-collapse: collapse;
`;

export const TableHead = styled.thead`
  background: #e4e7eb;
  border-left: 2px solid #e4e7eb;
`;

export const TableBody = styled.tbody`
  border-left: 2px solid #0f6efe;
`;

export const Trow = styled.tr``;

export const Tr = styled.tr`
  :nth-child(odd) {
    background-color: #f5f5f5;
  }
`;

export const Th = styled.th`
  color: #000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 16px;
`;

export const Td = styled.td`
  color: #000;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 16px;
`;
