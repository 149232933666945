import TextField from '@mui/material/TextField';
import { useField } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import { PatternFormat } from 'react-number-format';
import styled from 'styled-components';

const HvnInput = styled(TextField)`
  /* & .MuiFormControl-root .MuiTextField-root {
    max-width: 291.5px;
    max-height: 44px;
  } */
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  & label.Mui-focused {
    color: #6b7786;
  }
  & .MuiOutlinedInput-root {
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    color: #2b2f35;
    & fieldset {
      border-color: #6b7786;
      border-radius: 8px;
      border-width: 0.5px;
    }
    &.Mui-focused fieldset {
      border-color: #1b4d9d;
      border-radius: 8px;
      border-width: 0.8px;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

interface InputMaskProps {
  Name: string;
  Mask: string;
  Placeholder?: string;
  Require?: boolean;
  Disable?: boolean;
  DefaultValue?: string;
  Label?: string;
  Width?: string;
}

export const InputMask = ({
  Name,
  Mask,
  Placeholder,
  Require,
  Disable,
  DefaultValue,
  Label,
  Width,
}: InputMaskProps) => {
  const [Value, SetValue] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);
  const Gref = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(Name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
        SetValue(value);
      },
      clearValue: ref => {
        console.log(Gref.current?.formTarget);
        console.log(ref.current.value);
        ref.current.value = undefined;
        SetValue(null);
      },
    });
  }, [fieldName, registerField]);

  const VerifyErr = () => {
    if (error) {
      clearError();
    }
  };

  return (
    <PatternFormat
      format={Mask}
      customInput={HvnInput}
      name={Name}
      required={Require}
      disabled={Disable}
      defaultValue={DefaultValue}
      placeholder={Placeholder}
      getInputRef={Gref}
      label={Label}
      InputLabelProps={{ size: 'small' }}
      inputRef={inputRef}
      helperText={error || ''}
      error={!!error}
      sx={{ width: Width }}
      onChange={VerifyErr}
    />
  );
};
