/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable sonarjs/no-duplicate-string */
import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import Grid from '@mui/material/Grid';
import { useUser } from 'oidc-havan-ts';
import MenuItem from '@mui/material/MenuItem';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SearchIcon from '@mui/icons-material/Search';
import { FormHandles } from '@unform/core';
import { useNavigate } from 'react-router-dom';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PhotoIcon from '@mui/icons-material/Photo';
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from 'notistack';
import { Backdrop, CircularProgress, IconButton, Stack } from '@mui/material';
import _ from 'lodash';
import { Btn } from '../../../Core/Components/Button';
import { Menu } from '../../../Core/Components/Menu';
import { Page } from '../../../Core/Components/Page';
import { API } from '../../../Core/services/config-https';
import { Input } from '../../../Core/Components/Form/Input';
import { SelectInput } from '../../../Core/Components/Form/SelectInput';
import { Line } from '../../../Core/Components/Line';
import {
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
  Trow,
} from '../../../Core/Components/Table';
import { ActionButton } from '../../../Core/Components/ActionButton';
import { Tag } from '../../../Core/Components/Tag';
import './index.scss';
import { PostsApi } from '../../../Core/Types/Type';
import { HvnPagination } from '../../../Core/Components/HvnPagination';
import { PostFilter } from '../../../Core/Interfaces/PostFilter';
import { PostFilterType } from '../../../Core/Types/PostFilterType';
import { AlterarStatus } from './AlterarStatus';
import { DialogHvn } from '../../../Core/Components/Dialog';
import { InputDate } from '../../../Core/Components/Form/DateInput';
import { BreadCrumbsLinks } from '../../../Core/Components/BreadCrumbs/types';
import { useValidatePermition } from '../../../Core/hooks/useValidatePermition';

/* eslint-disable react/function-component-definition */

export const Posts = () => {
  const { accessToken, identity } = useUser();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const FormRef = useRef<FormHandles>(null);
  const [Render, SetRender] = useState<boolean>(false);
  const [ValidatePermition, SetValidatePermition] = useState<boolean>(false);
  const [Posts, SetPosts] = useState<Array<PostsApi>>([]);
  const [Count, SetCount] = useState<number>(0);
  const [Pesquisando, SetPesquisando] = useState<boolean>(true);
  const [Pagina, SetPagina] = useState<number>(1);
  const [Paginas, SetPaginas] = useState<number>(1);
  const [Filter, SetFilter] = useState<PostFilter>({
    params: {},
  });

  const breadcrumbs: BreadCrumbsLinks = {
    CurrentTitle: 'Listar Posts',
    Pass: [
      {
        Link: '/admin',
        Title: 'Home',
      },
    ],
  };

  const FormatDataToSend = (data: string, end?: boolean) => {
    const d = data.split('/');
    const time = end ? '23:59:59.000' : '00:00:00.000';
    return `${d[2]}-${d[1]}-${d[0]} ${time}`;
  };

  const ClearFields = () => {
    FormRef.current?.clearField('Status');
    FormRef.current?.clearField('DataInicial');
    FormRef.current?.clearField('DataFinal');
  };

  const handlePesquisa = useCallback(async () => {
    SetPesquisando(true);

    const filtro = Filter;

    // eslint-disable-next-line no-debugger
    // debugger;

    console.log(accessToken);

    await API.get('/Post', {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: {
        TotalRegisterPage: 7,
        Page: Pagina,
        ...filtro.params,
      },
    })
      .then(response => {
        const { data: Data } = response;
        SetPosts(Data.items);
        SetCount(Data.count);
        if (Pagina === 1) {
          SetPaginas(Data.pages);
        }
        setTimeout(() => {
          SetPesquisando(false);
        }, 2000);
      })
      .catch((e: any) => {
        console.log(e);
        enqueueSnackbar('Ocorreu um erro ao Pesquisar', { variant: 'error' });
        SetPesquisando(false);
      });
  }, [Filter, Pagina, accessToken, enqueueSnackbar]);

  const handleSubmit = (data: PostFilterType) => {
    console.log(data);
    const filtro: PostFilter = { params: {} };
    if (data.Id) {
      filtro.params.Id = data.Id;
    }
    if (data.Nome) {
      filtro.params.Nome = data.Nome;
    }
    if (data.UrlPromocao) {
      filtro.params.UrlPromocao = data.UrlPromocao;
    }
    if (data.DataInicial) {
      filtro.params.DataInicial = FormatDataToSend(data.DataInicial);
    }
    if (data.DataFinal) {
      filtro.params.DataFinal = FormatDataToSend(data.DataFinal, true);
    }
    if (data.Status && data.Status.toString() !== 'todos') {
      filtro.params.Status = data.Status;
    }
    console.log(filtro);
    SetFilter(filtro);
    SetPagina(1);
  };

  // useEffect(() => {
  //   console.log('=====================FILTRO====================');
  //   console.log(Filter);
  //   console.log('===============================================');
  // }, [Filter]);

  // useEffect(() => {
  //   console.log('=====================PAGINA====================');
  //   console.log(Pagina);
  //   console.log('===============================================');
  // }, [Pagina]);

  useEffect(() => {
    if (accessToken !== null) handlePesquisa();
  }, [Filter, Pagina, Render, accessToken, handlePesquisa]);

  useEffect(() => {
    if (accessToken !== null) handlePesquisa();
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_POSTS').then(v => {
        if (!v) {
          navigate('/admin');
        }
      });
    }
  }, [accessToken, handlePesquisa, identity?.codigo, navigate]);

  const OpenLink = (url: string) => {
    window.open(url, '_blank');
  };

  const handleTagStatus = (status: boolean) => {
    if (status) {
      return <Tag Color="#fff" BgColor="#01742D" Name="Ativo" />;
    }
    return <Tag Color="#fff" BgColor="#C31526" Name="Inativo" />;
  };

  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [MudarStatus, SetMudarStatus] = useState<boolean>();
  const [Id, SetId] = useState<number>();

  const handleClickOpenChangeStatus = (Id: number, status: boolean) => {
    SetId(Id);
    SetMudarStatus(!status);
    setOpenChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  const TimeOption = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  return (
    <Menu>
      <Page title="Listagem de Posts" BreadCrumb={breadcrumbs}>
        {ValidatePermition && (
          <Backdrop
            sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
            open={ValidatePermition}
          >
            <Stack alignItems="center">
              <CircularProgress color="inherit" />
              <h3>Validando Permições</h3>
            </Stack>
          </Backdrop>
        )}
        <DialogHvn
          onClose={handleCloseChangeStatus}
          open={openChangeStatus}
          tile={MudarStatus ? 'Ativar Post' : 'Inativar Post'}
          size="md"
          content={
            <AlterarStatus
              Id={Id as number}
              Status={MudarStatus as boolean}
              Close={handleCloseChangeStatus}
            />
          }
        />
        <div className="form-filter">
          <Form onSubmit={handleSubmit} ref={FormRef} id="search-post-filter">
            <Grid
              container
              spacing={1}
              sx={{
                '& > :not(style)': { marginTop: '16px' },
              }}
            >
              <Grid item xs={12}>
                <h2 id="form-title">Filtro Posts</h2>
              </Grid>
              <Grid item xs={1}>
                <Input
                  Name="Id"
                  Type="text"
                  Label="Identificador"
                  Width="100%"
                  Placeholder="Identificador"
                  OnlyNumber
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  Name="Nome"
                  Type="text"
                  Label="Nome"
                  Width="100%"
                  Placeholder="Nome"
                />
              </Grid>
              <Grid item xs={3}>
                <Input
                  Name="UrlPromocao"
                  Type="text"
                  Label="UrlPromocao"
                  Width="100%"
                  Placeholder="UrlPromocao"
                />
              </Grid>
              <Grid item xs={2}>
                <InputDate Name="DataInicial" Label="Data Inicial" />
              </Grid>
              <Grid item xs={2}>
                <InputDate Name="DataFinal" Label="Data Final" />
              </Grid>
              <Grid item xs={2}>
                <SelectInput Name="Status" Label="Situacao">
                  <MenuItem value="todos">Todos</MenuItem>
                  <MenuItem value="true">Ativo</MenuItem>
                  <MenuItem value="false">Inativo</MenuItem>
                </SelectInput>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ textAlign: 'right' }}
                sx={{
                  '& > :not(style)': { marginLeft: '8px' },
                }}
              >
                <Btn
                  BtnType="reset"
                  Variant="outlined"
                  Type="regular"
                  Text="Limpar Filtro"
                  StartIcon={<FilterListOffIcon />}
                  OnClick={() => {
                    ClearFields();
                  }}
                />
                <Btn
                  Id="search-post-filter-button"
                  BtnType="submit"
                  Variant="contained"
                  Type="primary"
                  Text="Pesquisar"
                  StartIcon={<SearchIcon />}
                />
              </Grid>
              <Grid item xs={12}>
                <Line />
              </Grid>
            </Grid>
          </Form>
        </div>
        <div className="table-filtro">
          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            <Grid item xs={8}>
              <h3 id="table-title">Posts ({Count})</h3>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <Btn
                BtnType="button"
                Variant="contained"
                Type="primary"
                Text="Adicionar Post"
                StartIcon={<AddIcon />}
                OnClick={() => {
                  window.location.href = '/post';
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            <Table>
              <TableHead>
                <Trow>
                  <Th className="CenterText">Id</Th>
                  <Th>Nome</Th>
                  <Th>Link</Th>
                  <Th />
                  <Th className="CenterText">Inicio</Th>
                  <Th className="CenterText">Termino</Th>
                  <Th className="CenterText">Status</Th>
                  <Th className="CenterText">Ações</Th>
                </Trow>
              </TableHead>
              {Posts.length ? (
                <TableBody>
                  {Posts.map((item: PostsApi) => {
                    return (
                      <Tr key={item.id}>
                        <Td className="CenterText">{item.id}</Td>
                        <Td>{item.nome}</Td>
                        <Td>
                          {item.urlPromocao == null ? (
                            <i>SEM LINK</i>
                          ) : (
                            item.urlPromocao
                          )}
                        </Td>
                        <Td className="CenterText">
                          <IconButton
                            onClick={() => {
                              item.urlPromocao == null
                                ? ''
                                : OpenLink(item.urlPromocao);
                            }}
                          >
                            {item.urlPromocao == null ? (
                              <LinkOffIcon style={{ color: '#ff0004' }} />
                            ) : (
                              <LinkIcon style={{ color: '#0F6EFE' }} />
                            )}
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              OpenLink(
                                `${process.env.REACT_APP_ANEXO_URL}${item.anexo}`,
                              );
                            }}
                          >
                            <PhotoIcon style={{ color: '#0F6EFE' }} />
                          </IconButton>
                        </Td>
                        <Td className="CenterText">
                          {new Date(item.dataInicial).toLocaleDateString(
                            'pt-br',
                            TimeOption as any,
                          )}
                        </Td>
                        <Td className="CenterText">
                          {new Date(item.dataFinal).toLocaleDateString(
                            'pt-br',
                            TimeOption as any,
                          )}
                        </Td>
                        <Td className="CenterText">
                          {handleTagStatus(item.status)}
                        </Td>
                        <Td className="CenterText">
                          <ActionButton>
                            <MenuItem
                              onClick={() => {
                                navigate(`/post/${item.id}`);
                              }}
                            >
                              Alterar
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClickOpenChangeStatus(
                                  item.id,
                                  item.status,
                                );
                              }}
                            >
                              {item.status ? 'Inativar' : 'Ativar'}
                            </MenuItem>
                          </ActionButton>
                        </Td>
                      </Tr>
                    );
                  })}
                </TableBody>
              ) : (
                ''
              )}
            </Table>
          </Grid>
          {Posts.length ? (
            <Grid container spacing={1} sx={{ marginTop: '8px' }}>
              <Grid item xs={12} sx={{ textAlign: 'left' }}>
                <HvnPagination
                  Count={Paginas}
                  PaginationProp={{
                    onChange: (e: any) => {
                      SetPagina(Number(e.target.textContent));
                    },
                    defaultPage: Pagina,
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              {Pesquisando ? (
                <Grid container spacing={1} sx={{ marginTop: '16px' }}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  className="notFound"
                  sx={{ marginTop: '16px' }}
                >
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <HighlightOffIcon sx={{ fontSize: 54, color: '#C7CDD6' }} />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <h2>Nenhum resultado encontrado</h2>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </div>
      </Page>
    </Menu>
  );
};
