import { styled, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useField } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';

interface InputProps {
  Name: string;
  Require?: boolean;
  Disable?: boolean;
  DisablePast?: boolean;
  DefaultValue?: string | number | dayjs.Dayjs | Date | null | undefined;
  Label: string;
  Width?: string;
}

const HvnDateInput = styled(TextField)`
  & .MuiFormControl-root .MuiTextField-root {
    max-width: 291.5px;
    max-height: 44px;
  }
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  & label.Mui-focused {
    color: #6b7786;
  }
  & .MuiOutlinedInput-root {
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    color: #2b2f35;
    & fieldset {
      border-color: #6b7786;
      border-radius: 8px;
      border-width: 0.5px;
    }
    &.Mui-focused fieldset {
      border-color: #1b4d9d;
      border-radius: 8px;
      border-width: 0.8px;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

export const InputDate = ({
  Label,
  Name,
  Require,
  Disable,
  DisablePast,
  DefaultValue,
  Width,
}: InputProps) => {
  const inputDateRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(Name);

  const [Value, SetValue] = useState<Dayjs | null>(
    DefaultValue ? dayjs(DefaultValue) : null,
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputDateRef.current,
      getValue: ref => {
        return ref.value;
      },
      setValue: (ref, value) => {
        ref.value = value;
      },
      clearValue: ref => {
        ref.value = '';
        SetValue(null);
      },
    });
  }, [fieldName, registerField]);

  const handleChange = (newValue: Dayjs | null) => {
    SetValue(newValue);
    if (error) {
      clearError();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={Value}
        onChange={handleChange}
        label={Label}
        inputFormat="DD/MM/YYYY"
        readOnly={Disable}
        inputRef={inputDateRef}
        disablePast={DisablePast}
        renderInput={(params: any) => (
          <HvnDateInput
            {...params}
            InputLabelProps={{ size: 'small' }}
            required={Require}
            disabled={Disable}
            defaultValue={DefaultValue}
            style={{ width: Width }}
            helperText={error || ''}
            error={!!error}
            sx={{
              svg: { fontSize: 16 },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
