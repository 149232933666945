import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useField } from '@unform/core';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Item } from './types';

interface InputProps {
  Name: string;
  Require?: boolean;
  Disable?: boolean;
  Label: string;
  Items?: Array<Item>;
  DefaultValue?: string | number | readonly string[] | undefined;
  OnChangeFunction?: (e: any) => void;
  children?: React.ReactNode;
}

const HvnInput = styled(TextField)`
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  & label.Mui-focused {
    color: #6b7786;
  }
  & .MuiOutlinedInput-root {
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    & fieldset {
      border-color: #6b7786;
      border-radius: 8px;
    }
    &.Mui-focused fieldset {
      border-color: #1b4d9d;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

export const SelectInput: React.FC<InputProps> = ({
  Label,
  Name,
  Require,
  Disable,
  Items,
  DefaultValue,
  OnChangeFunction,
  children,
}: InputProps) => {
  const SelectRef = useRef<HTMLSelectElement>(null);
  const [Value, SetValue] = useState<any>(DefaultValue || null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(Name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: SelectRef.current,
      getValue: ref => {
        return ref.node.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.node.value = '';
        SetValue(null);
      },
    });
  }, [fieldName, registerField]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetValue(event.target.value);
    if (error) {
      clearError();
    }
  };

  const thisCalls = (e: any) => {
    handleChange(e);
    if (OnChangeFunction) OnChangeFunction(e);
  };

  return (
    <HvnInput
      select
      fullWidth
      disabled={Disable}
      inputRef={SelectRef}
      onChange={thisCalls}
      value={Value}
      variant="outlined"
      required={Require}
      label={Label}
      helperText={error || ''}
      error={!!error}
      defaultValue={DefaultValue}
      InputLabelProps={{ size: 'small' }}
    >
      {children ||
        Items?.map((option: Item, index: number) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
    </HvnInput>
  );
};
