import { ClaimAPI } from '../services/config-https';

interface IValidationPermission {
  Sucesso: boolean;
}

export const useValidatePermition = async (
  codigo: string | undefined,
  permision: string,
): Promise<boolean> => {
  if (codigo === undefined) return false;

  let retorno = false;

  await ClaimAPI.get<IValidationPermission>('Funcionalidade/TemAcesso', {
    params: {
      usuario: codigo,
      funcionalidade: permision,
    },
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  }).then(r => {
    retorno = r.data.Sucesso;
  });

  return retorno as boolean;
};
