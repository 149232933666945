/* eslint-disable react/function-component-definition */
import Pagination, { PaginationProps } from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import './style.scss';
import { makeStyles } from '@mui/material/styles';
import { memo } from 'react';

interface HvnPaginationProps {
  Count: number;
  PaginationProp?: PaginationProps;
}

const HvnPaginationComponent = ({
  Count,
  PaginationProp,
}: HvnPaginationProps) => {
  return (
    <Stack spacing={2}>
      <Pagination
        count={Count}
        shape="rounded"
        hidePrevButton
        hideNextButton
        renderItem={item => (
          <PaginationItem classes={{ selected: 'select' }} {...item} />
        )}
        {...PaginationProp}
      />
    </Stack>
  );
};

export const HvnPagination = memo(HvnPaginationComponent);
