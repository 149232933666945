import { Button } from './style';

interface IButton {
  title: string;
  text: string;
  link: string;
}

export function AcesseAquiButton({ text, title, link }: IButton) {
  return (
    <Button
      type="button"
      onClick={() => {
        window.open(link, '_blank');
      }}
      title={title}
    >
      {text}
    </Button>
  );
}
