/* eslint-disable react/function-component-definition */
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import React, { ReactElement, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import HomeIcon from '@mui/icons-material/Home';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import LaunchIcon from '@mui/icons-material/Launch';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import './index.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'oidc-havan-ts';
import { useValidatePermition } from '../../hooks/useValidatePermition';

const drawerWidth = 340;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface IMenu {
  children: ReactElement<any, any>;
}

export const Menu = ({ children }: IMenu) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { identity, logout } = useUser();

  const [PostPermition, SetPostPermition] = useState<boolean>();
  const [MktPermition, SetMktPermition] = useState<boolean>();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_POSTS').then(v =>
        SetPostPermition(v),
      );
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_CONTATOS').then(v =>
        SetMktPermition(v),
      );
    }
  }, [identity]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        style={{
          background:
            'linear-gradient(90deg, rgba(0,64,255,1) 33%, rgba(108,194,230,1) 120%)',
        }}
        className="MenuHvn"
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <h3>Havan</h3>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <h4 style={{ marginRight: '50%' }}>{`Olá, ${
            identity?.nome?.split(' ')[0]
          }`}</h4>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Home" disablePadding>
            <ListItemButton
              onClick={() => {
                navigate('/admin');
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
          {PostPermition ? (
            <ListItem key="Listar Posts" disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate('/posts');
                }}
              >
                <ListItemIcon>
                  <DynamicFeedIcon />
                </ListItemIcon>
                <ListItemText primary="Listar Posts" />
              </ListItemButton>
            </ListItem>
          ) : (
            ''
          )}
          {MktPermition ? (
            <ListItem key="Contatos Marketing" disablePadding>
              <ListItemButton
                onClick={() => {
                  navigate('/contatos-marketing');
                }}
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Contatos Marketing" />
              </ListItemButton>
            </ListItem>
          ) : (
            ''
          )}
          <ListItem key="Abrir Acesse Aqui" disablePadding>
            <ListItemButton
              onClick={() => {
                window.open('/acesse-aqui', '_blank');
              }}
            >
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary="Abrir Acesse Aqui" />
            </ListItemButton>
          </ListItem>
          <ListItem key="Abrir Contatos Marketing" disablePadding>
            <ListItemButton
              onClick={() => {
                window.open('/contatos', '_blank');
              }}
            >
              <ListItemIcon>
                <LaunchIcon />
              </ListItemIcon>
              <ListItemText primary="Abrir Contatos Marketing" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem key="HvnProfile" disablePadding>
            <ListItemButton onClick={logout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sair" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
};
