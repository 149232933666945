import styled, { css } from 'styled-components';

interface IDropContainer {
  isDragActive: boolean;
  isDragReject: boolean;
}

interface IUploadMensage {
  type?: 'Erro' | 'Sucess' | undefined;
}

const Active = css`
  border-color: rgb(49, 164, 83);
`;

const Reject = css`
  border-color: rgb(234, 16, 30);
`;

const ActiveColor = css`
  color: rgb(49, 164, 83);
`;

const RejectColor = css`
  color: rgb(234, 16, 30);
`;

export const DropContainer = styled.div<IDropContainer>`
  width: auto;
  height: 150px;
  margin-bottom: 35px;
  border: 1px dashed #b2b2b2;
  border-radius: 4px;
  cursor: pointer;
  ${props => props.isDragActive && Active}
  ${props => props.isDragReject && Reject}
`;

export const UploadMensage = styled.div<IUploadMensage>`
  color: #b2b2b2;
  text-align: center;
  margin-top: 45px;
  ${props => props.type === 'Erro' && RejectColor}
  ${props => props.type === 'Sucess' && ActiveColor}
`;
