/* eslint-disable react/function-component-definition */
import Button from '@mui/material/Button';
import { useField } from '@unform/core';
import { useEffect } from 'react';
import './style.scss';

interface BtnProps {
  Id?: string;
  Variant: 'contained' | 'outlined' | 'text';
  Text: string;
  BtnType: 'button' | 'submit' | 'reset';
  Type: 'primary' | 'secundary' | 'regular' | 'danger';
  Size?: 'small' | 'medium' | 'large';
  Disable?: boolean;
  ModalBtn?: boolean;
  Width?: string;
  OnClick?: () => void;
  StartIcon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
  EndIcon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

export const Btn = ({
  Id,
  Variant,
  Text,
  BtnType,
  Type,
  StartIcon,
  EndIcon,
  OnClick,
  Size,
  Disable,
  ModalBtn,
  Width,
}: BtnProps) => {
  if (StartIcon) {
    return (
      <Button
        id={Id}
        type={BtnType}
        variant={Variant}
        startIcon={StartIcon}
        className={Disable ? `btn-${Type}-disable` : `btn-${Type}`}
        onClick={OnClick}
        sx={{ width: Width }}
        size={Size || 'medium'}
        disabled={Disable}
      >
        {Text}
      </Button>
    );
  }
  if (EndIcon) {
    return (
      <Button
        id={Id}
        type={BtnType}
        variant={Variant}
        endIcon={EndIcon}
        className={Disable ? `btn-${Type}-disable` : `btn-${Type}`}
        onClick={OnClick}
        sx={{ width: Width }}
        size={Size || 'medium'}
        disabled={Disable}
      >
        {Text}
      </Button>
    );
  }

  return (
    <Button
      id={Id}
      type={BtnType}
      variant={Variant}
      className={Disable ? `btn-${Type}-disable` : `btn-${Type}`}
      onClick={OnClick}
      sx={{ width: Width }}
      size={Size || 'medium'}
      disabled={Disable}
    >
      {Text}
    </Button>
  );
};
