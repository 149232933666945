import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useUser } from 'oidc-havan-ts';
import { Btn } from '../../../../Core/Components/Button';
import { Line } from '../../../../Core/Components/Line';
import { API } from '../../../../Core/services/config-https';

interface IAlterarStatusContato {
  Id: number;
  Status: boolean;
  Close: () => void;
}

export const AlterarStatusContato = ({
  Id,
  Status,
  Close,
}: IAlterarStatusContato) => {
  const { enqueueSnackbar } = useSnackbar();
  const { accessToken } = useUser();

  const handleMudartatus = useCallback(async () => {
    API.patch(
      '/ContatosMarketing/AtivarInativar',
      {
        id: Id,
        status: Status,
      },
      {
        headers: { Authorization: accessToken },
      },
    )
      .then(response => {
        const { data: Data } = response;
        if (Data.success) {
          enqueueSnackbar(`Contato ${Id} ${Status ? 'Ativado' : 'Inativado'}`, {
            variant: 'success',
          });
          setTimeout(() => {
            window.location.href = '/contatos-marketing';
          }, 4000);
        }
      })
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao Alterar o Status', {
          variant: 'error',
        });
      });
  }, [Id, Status, accessToken, enqueueSnackbar]);

  return (
    <div className="alterStatusContato">
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '16px',
        }}
      >
        <Grid item xs={12}>
          <p id="AlterarStatusQuestion">
            {Status
              ? `Ativar o Contato ${Id} ? `
              : `Inativar o Contato ${Id} ?`}
          </p>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        sx={{
          marginTop: '32px',
        }}
      >
        <Grid item xs={12}>
          <Line />
        </Grid>
        <Grid item xs={6}>
          <Btn
            Text="Fechar"
            OnClick={() => {
              Close();
            }}
            BtnType="button"
            Type="regular"
            Variant="outlined"
            Size="large"
            Width="100%"
          />
        </Grid>
        <Grid item xs={6}>
          <Btn
            Text={Status ? 'Ativar' : 'Inativar'}
            BtnType="button"
            Type="primary"
            Variant="contained"
            Size="large"
            Width="100%"
            OnClick={handleMudartatus}
          />
        </Grid>
      </Grid>
    </div>
  );
};
