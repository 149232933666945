import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './Global.scss';
import { HavanAuthProvider, OidcConfig } from 'oidc-havan-ts';
import { MainRoutes } from './Routes';

const oidcConfig: OidcConfig = {
  authority: 'https://openid.apps.havan.com.br',
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <HavanAuthProvider oidcConfig={oidcConfig}>
      <MainRoutes />
    </HavanAuthProvider>
  </React.StrictMode>,
);
