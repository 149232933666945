/* eslint-disable react/function-component-definition */
import { styled, TextField } from '@mui/material';
import { useField } from '@unform/core';
import { useEffect, useRef } from 'react';

interface InputProps {
  Name: string;
  Placeholder?: string;
  Require?: boolean;
  Disable?: boolean;
  DefaultValue?: unknown;
  Type: React.HTMLInputTypeAttribute;
  Label?: string;
  MaxLength?: number;
  OnlyNumber?: boolean;
  Width?: string;
}

const HvnInput = styled(TextField)`
  /* & .MuiFormControl-root .MuiTextField-root {
    max-width: 291.5px;
    max-height: 44px;
  } */
  & label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
  & label.Mui-focused {
    color: #6b7786;
  }
  & .MuiOutlinedInput-root {
    outline: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Poppins';
    color: #2b2f35;
    & fieldset {
      border-color: #6b7786;
      border-radius: 8px;
      border-width: 0.5px;
    }
    &.Mui-focused fieldset {
      border-color: #1b4d9d;
      border-radius: 8px;
      border-width: 0.8px;
    }
  }
  & .MuiOutlinedInput-input {
    padding: 8px 14px;
  }
`;

export const Input: React.FC<InputProps> = ({
  Label,
  Name,
  Placeholder,
  Require,
  Disable,
  DefaultValue,
  Type,
  MaxLength,
  OnlyNumber,
  Width,
}: InputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(Name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: ref => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  function onlyNumber(evt: any) {
    const theEvent = evt || window.event;
    let key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
    const regex = /^[0-9]+$/;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  }

  const VerifyErr = () => {
    if (error) {
      clearError();
    }
  };

  return (
    <HvnInput
      disabled={Disable}
      type={Type}
      inputRef={inputRef}
      variant="outlined"
      required={Require}
      defaultValue={DefaultValue || defaultValue}
      label={Label}
      name={Name}
      placeholder={Placeholder}
      helperText={error || ''}
      error={!!error}
      InputLabelProps={{ size: 'small' }}
      inputProps={{ maxLength: MaxLength }}
      sx={{ width: Width }}
      onChange={VerifyErr}
      onKeyPress={e => {
        if (OnlyNumber) onlyNumber(e);
      }}
    />
  );
};
