/* eslint-disable react/function-component-definition */

import { SnackbarProvider } from 'notistack';
import { HavanAuthProvider, OidcConfig } from 'oidc-havan-ts';
import './App.scss';

const App = ({
  children,
  Autentication,
}: {
  children: JSX.Element;
  Autentication?: boolean;
}) => {
  if (Autentication) {
    return (
      <div className="app auth">
        <SnackbarProvider
          maxSnack={4}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {children}
        </SnackbarProvider>
      </div>
    );
  }
  return <div className="app">{children}</div>;
};

export default App;
