/* eslint-disable no-debugger */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/cognitive-complexity */
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import { Form } from '@unform/web';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from 'oidc-havan-ts';
import { BreadCrumbsLinks } from '../../../Core/Components/BreadCrumbs/types';
import { Btn } from '../../../Core/Components/Button';
import { InputDate } from '../../../Core/Components/Form/DateInput';
import { Input } from '../../../Core/Components/Form/Input';
import { TextArea } from '../../../Core/Components/Form/TextArea';
import { Menu } from '../../../Core/Components/Menu';
import { Page } from '../../../Core/Components/Page';
import { Upload } from '../../../Core/Components/Upload';
import { useAuth } from '../../../Core/Context/useAuth';
import { API } from '../../../Core/services/config-https';
import { InsertPost, PostsApi } from '../../../Core/Types/Type';
import './index.scss';
import { useValidatePermition } from '../../../Core/hooks/useValidatePermition';

interface FileProps {
  file: [];
  id: string;
  name: string;
  preview: string;
  downloadURL: string;
  url: string;
  type: string;
  progress: number;
  upload: boolean;
  erro: boolean;
}

export const Post = () => {
  const { Id } = useParams() as any;
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [Pesquisando, SetPesquisando] = useState<boolean>(!Id);
  const [Post, SetPost] = useState<PostsApi>();
  const [Anexos, SetAnexos] = useState<Array<FileProps>>([]);
  const navigate = useNavigate();
  const { accessToken, identity } = useUser();

  const Breadcrumbs: BreadCrumbsLinks = {
    CurrentTitle: Id ? `Post ${Id}` : 'Novo Post',
    Pass: [
      {
        Link: '/admin',
        Title: 'Home',
      },
      {
        Title: 'Listagem de Posts',
        Link: '/posts',
      },
    ],
  };

  const FormatData = (data: string) => {
    const d = data.split('/');
    return `${d[2]}/${d[1]}/${d[0]}`;
  };

  const FormatDataApi = (data: string) => {
    const d = data.replace('T', '-').split('-');
    return `${d[0]}/${d[1]}/${d[2]}`;
  };

  const FormatDataToSend = (data: string, end?: boolean) => {
    const d = data.split('/');
    const time = end ? 'T23:59:59' : 'T00:00:00';

    return `${d[2]}-${d[1]}-${d[0]}${time}`;
  };

  const handleVerifyDates = (Inicio: string, Final: string) => {
    if (Inicio.length < 10 || Final.length < 10) {
      enqueueSnackbar(
        'A Data Inicial ou a Data Final estão em um formato incorreto',
        {
          variant: 'error',
        },
      );
      return false;
    }
    const DI = new Date(FormatData(Inicio));
    const DF = new Date(FormatData(Final));
    if (DI > DF) {
      enqueueSnackbar(
        'A Data Inicial não pode ser maior ou igual a Data Final.',
        {
          variant: 'error',
        },
      );
      return false;
    }

    return true;
  };

  const handleSubmit = (data: any) => {
    const anexo = document.getElementsByClassName(
      'anexos-data',
    ) as HTMLCollectionOf<HTMLInputElement>;
    console.log(data, anexo);
    if (handleVerifyDates(data.DataInicial, data.DataFinal)) {
      if (anexo.length) {
        const BodyData: InsertPost = {
          id: Id || 0,
          nome: data.Nome,
          urlPromocao: data.UrlPromocao,
          descricao: data.Descricao,
          dataInicial: FormatDataToSend(data.DataInicial),
          dataFinal: FormatDataToSend(data.DataFinal, true),
          status: true,
          idAnexoAcesseAqui: Number(anexo[0].value),
        };
        if (Id) {
          API.put('/Post', BodyData, {
            headers: { Authorization: accessToken },
          })
            .then((response: any) => {
              if (response.data.success) {
                if (Id) {
                  enqueueSnackbar(`Post ${response.data.model} Alterado`, {
                    variant: 'success',
                  });
                } else {
                  enqueueSnackbar(`Post ${response.data.model} Cadastrado`, {
                    variant: 'success',
                  });
                }
                setTimeout(() => {
                  window.location.href = '/posts';
                }, 4000);
              }
            })
            .catch((error: any) => {
              console.log(error);
              if (error.message === 'Network Error') {
                enqueueSnackbar('Seu Token Expirou!', { variant: 'error' });
                setTimeout(() => {
                  auth.signOut();
                }, 4000);
              } else if (error) {
                enqueueSnackbar(error.response.data, {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar(
                  `Ocoreu um erro a ${Id ? 'alterar' : 'cadastrar'}`,
                  {
                    variant: 'error',
                  },
                );
              }
            });
        } else {
          API.post('/Post', BodyData, {
            headers: { Authorization: accessToken },
          })
            .then((response: any) => {
              if (response.data.success) {
                if (Id) {
                  enqueueSnackbar(`Post ${response.data.model} Alterado`, {
                    variant: 'success',
                  });
                } else {
                  enqueueSnackbar(`Post ${response.data.model} Cadastrado`, {
                    variant: 'success',
                  });
                }
                setTimeout(() => {
                  window.location.href = '/posts';
                }, 4000);
              }
            })
            .catch((error: any) => {
              console.log(error);
              if (error.message === 'Network Error') {
                enqueueSnackbar('Seu Token Expirou!', { variant: 'error' });
                setTimeout(() => {
                  auth.signOut();
                }, 4000);
              } else if (error) {
                enqueueSnackbar(error.response.data, {
                  variant: 'error',
                });
              } else {
                enqueueSnackbar(
                  `Ocoreu um erro a ${Id ? 'alterar' : 'cadastrar'}`,
                  {
                    variant: 'error',
                  },
                );
              }
            });
        }
      } else {
        enqueueSnackbar('Um anexo é necessário', { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    console.log('Anexos Files ==========================');
    console.log(Anexos);
  }, [Anexos]);

  const GetAnexos = (anexoId: string, anexoNameBlob: string) => {
    SetAnexos([]);
    const files: Array<FileProps> = [];
    files.push({
      id: anexoId,
      upload: true,
      name: `AcesseAqui-${anexoId}`,
      preview: `${process.env.REACT_APP_ANEXO_URL}${anexoNameBlob}`,
      url: `${process.env.REACT_APP_ANEXO_URL}${anexoNameBlob}`,
      downloadURL: '',
      erro: false,
      file: [],
      progress: 100,
      type: '',
    });
    if (files.length > 1) {
      files.shift();
    }
    SetAnexos(files);
  };

  const handleBuscarPost = useCallback(async () => {
    await API.get('/Post', {
      headers: { Authorization: accessToken },
      params: { Id },
    })
      .then(response => {
        const { data: Data } = response;
        SetAnexos([]);
        console.log(Data.items[0]);
        SetPost(Data.items[0]);
        GetAnexos(Data.items[0].idAnexoAcesseAqui, Data.items[0].anexo);
        setTimeout(() => {
          SetPesquisando(true);
        }, 2000);
      })
      .catch(error => {
        console.log(error);
        if (error.message === 'Network Error') {
          enqueueSnackbar('Seu Token Expirou!', { variant: 'error' });
          setTimeout(() => {
            auth.signOut();
          }, 4000);
        }
      });
  }, [Id, accessToken, auth, enqueueSnackbar]);

  useEffect(() => {
    if (accessToken !== null && Id) handleBuscarPost();
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_POSTS').then(v => {
        if (!v) {
          navigate('/admin');
        }
      });
    }
  }, [accessToken, enqueueSnackbar, handleBuscarPost, identity, navigate]);

  return (
    <Menu>
      <Page title={Id ? `Post ${Id}` : 'Novo Post'} BreadCrumb={Breadcrumbs}>
        {Pesquisando ? (
          <Grow in={Id ? Pesquisando : true}>
            <div className="post-form">
              <Form onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    '& > :not(style)': { marginTop: '16px' },
                  }}
                >
                  <Grid item xs={12}>
                    <h2>{Id ? 'Alterar Post' : 'Cadastrar Post'}</h2>
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      Name="Nome"
                      Type="text"
                      Label="Nome"
                      Placeholder="Digite o nome..."
                      Require
                      Width="100%"
                      DefaultValue={Id ? Post?.nome : ''}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputDate
                      Name="DataInicial"
                      Label="Data Inicial"
                      Require
                      DefaultValue={
                        Id ? FormatDataApi(Post?.dataInicial as string) : ''
                      }
                      DisablePast
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputDate
                      Name="DataFinal"
                      Label="Data Final"
                      Require
                      DefaultValue={
                        Id ? FormatDataApi(Post?.dataFinal as string) : ''
                      }
                      DisablePast
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      Name="UrlPromocao"
                      Type="text"
                      Label="Url da Promoção"
                      Placeholder="Digite a url da Promoção..."
                      Width="100%"
                      DefaultValue={Id ? Post?.urlPromocao : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextArea
                      Label="Descrição"
                      Name="Descricao"
                      Placeholder="Digite aqui sua descrição deste post..."
                      Require
                      FullWidth
                      MaxLength={255}
                      DefaultValue={Id ? Post?.descricao : ''}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Upload Files={Anexos} />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    marginTop: '64px',
                  }}
                >
                  <Grid item xs={12} style={{ textAlign: 'right' }}>
                    <Btn
                      BtnType="submit"
                      Type="primary"
                      Variant="contained"
                      Text={Id ? 'Salvar Alterações' : 'Cadastrar'}
                    />
                  </Grid>
                </Grid>
              </Form>
            </div>
          </Grow>
        ) : (
          <Grid container spacing={1} sx={{ marginTop: '5px' }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Page>
    </Menu>
  );
};
