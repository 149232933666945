/* eslint-disable react/jsx-no-useless-fragment */
import { Avatar, CircularProgress, IconButton, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FormHandles } from '@unform/core';
import AddIcon from '@mui/icons-material/Add';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useUser } from 'oidc-havan-ts';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import SearchIcon from '@mui/icons-material/Search';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import _ from 'lodash';
import { Form } from '@unform/web';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Btn } from '../../../Core/Components/Button';
import { Input } from '../../../Core/Components/Form/Input';
import { SelectInput } from '../../../Core/Components/Form/SelectInput';
import { Menu } from '../../../Core/Components/Menu';
import { Page } from '../../../Core/Components/Page';
import {
  Table,
  TableBody,
  TableHead,
  Td,
  Th,
  Tr,
  Trow,
} from '../../../Core/Components/Table';
import { Tag } from '../../../Core/Components/Tag';
import { ActionButton } from '../../../Core/Components/ActionButton';
import './index.scss';
import {
  ContatoMarketingFilterType,
  ContatosMarketingApi,
} from '../../../Core/Types/Type';
import { HvnPagination } from '../../../Core/Components/HvnPagination';
import { ContatosMarketingFilter } from '../../../Core/Interfaces/ContatosMarketingFilter';
import { API } from '../../../Core/services/config-https';
import { DialogHvn } from '../../../Core/Components/Dialog';
import { AlterarStatusContato } from './AlterarStatus';
import { BreadCrumbsLinks } from '../../../Core/Components/BreadCrumbs/types';
import { useValidatePermition } from '../../../Core/hooks/useValidatePermition';

export const ContatosMarketings = () => {
  const navigate = useNavigate();
  const [Pesquisando, SetPesquisando] = useState<boolean>(true);
  const [Contatos, SetContatos] = useState<Array<ContatosMarketingApi>>([]);
  const [Count, SetCount] = useState<number>(0);
  const [Pagina, SetPagina] = useState<number>(1);
  const [Paginas, SetPaginas] = useState<number>(1);
  const { enqueueSnackbar } = useSnackbar();
  const [Filtro, SetFiltro] = useState<ContatosMarketingFilter>({
    params: {},
  });
  const FormRef = useRef<FormHandles>(null);
  const { accessToken, identity } = useUser();

  const breadcrumbs: BreadCrumbsLinks = {
    CurrentTitle: 'Listar Contatos',
    Pass: [
      {
        Link: '/admin',
        Title: 'Home',
      },
    ],
  };

  const ClearFields = () => {
    FormRef.current?.clearField('Status');
    FormRef.current?.clearField('Telefone');
  };

  const handlePesquisa = useCallback(async () => {
    SetPesquisando(true);

    const filtro = Filtro;

    filtro.params.TotalRegisterPage = 7;
    filtro.params.Page = Pagina;

    await API.get('/ContatosMarketing', {
      headers: { Authorization: accessToken },
      params: filtro.params,
    })
      .then(response => {
        const { data: Data } = response;
        SetContatos(Data.items);
        SetCount(Data.count);
        if (Pagina === 1) {
          SetPaginas(Data.pages);
        }
        setTimeout(() => {
          SetPesquisando(false);
        }, 2000);
      })
      .catch(() => {
        enqueueSnackbar('Ocorreu um erro ao Pesquisar', { variant: 'error' });
        SetPesquisando(false);
      });
  }, [Filtro, Pagina, accessToken, enqueueSnackbar]);

  useEffect(() => {
    if (accessToken !== null) {
      handlePesquisa();
    }
  }, [Filtro, Pagina, accessToken, handlePesquisa]);

  useEffect(() => {
    if (accessToken !== null) handlePesquisa();
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_CONTATOS').then(v => {
        if (!v) {
          navigate('/admin');
        }
      });
    }
  }, [accessToken, identity, handlePesquisa, navigate]);

  const handleSubmit = (data: ContatoMarketingFilterType) => {
    const filtro: ContatosMarketingFilter = { params: {} };
    if (data.Id) {
      filtro.params.Id = data.Id;
    }
    if (data.Nome) {
      filtro.params.Nome = data.Nome;
    }
    if (data.Telefone) {
      filtro.params.Telefone = data.Telefone;
    }
    if (data.Status && data.Status.toString() !== 'todos') {
      filtro.params.Status = data.Status;
    }
    SetFiltro(filtro);
    SetPagina(1);
  };

  const handleTagStatus = (status: boolean) => {
    if (status) {
      return <Tag Color="#fff" BgColor="#01742D" Name="Ativo" />;
    }
    return <Tag Color="#fff" BgColor="#C31526" Name="Inativo" />;
  };

  const [openChangeStatus, setOpenChangeStatus] = useState(false);
  const [MudarStatus, SetMudarStatus] = useState<boolean>();
  const [Id, SetId] = useState<number>();

  const handleClickOpenChangeStatus = (Id: number, status: boolean) => {
    SetId(Id);
    SetMudarStatus(!status);
    setOpenChangeStatus(true);
  };

  const handleCloseChangeStatus = () => {
    setOpenChangeStatus(false);
  };

  const LinkWhatsApp = (phone: string) => {
    const WhatsAppNumber = phone
      .replaceAll(' ', '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');

    window.open(
      `https://api.whatsapp.com/send?1=pt_BR&phone=55${WhatsAppNumber}`,
      '_blank',
    );
  };

  return (
    <Menu>
      <Page title="Contatos Marketing" BreadCrumb={breadcrumbs}>
        <DialogHvn
          onClose={handleCloseChangeStatus}
          open={openChangeStatus}
          tile={MudarStatus ? 'Ativar Post' : 'Inativar Post'}
          size="md"
          content={
            <AlterarStatusContato
              Id={Id as number}
              Status={MudarStatus as boolean}
              Close={handleCloseChangeStatus}
            />
          }
        />
        <div className="form-filter">
          <Form onSubmit={handleSubmit} ref={FormRef}>
            <Grid
              container
              spacing={1}
              sx={{
                '& > :not(style)': { marginTop: '16px' },
              }}
            >
              <Grid item xs={12}>
                <h2 id="form-title">Filtro Contatos Marketing</h2>
              </Grid>
              <Grid item xs={1}>
                <Input
                  Name="Id"
                  Label="Identificador"
                  Width="100%"
                  Placeholder="Identificador"
                  Type="text"
                  OnlyNumber
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  Name="Nome"
                  Label="Nome"
                  Width="100%"
                  Placeholder="Nome"
                  Type="text"
                />
              </Grid>
              <Grid item xs={2}>
                <Input
                  Type="text"
                  Name="Telefone"
                  Label="Telefone"
                  Placeholder="Telefone"
                  Width="100%"
                  OnlyNumber
                  MaxLength={16}
                />
              </Grid>
              <Grid item xs={2}>
                <SelectInput Name="Status" Label="Situação">
                  <MenuItem value="todos">Todos</MenuItem>
                  <MenuItem value="true">Ativo</MenuItem>
                  <MenuItem value="false">Inativo</MenuItem>
                </SelectInput>
              </Grid>
              <Grid
                item
                xs={5}
                style={{ textAlign: 'right' }}
                sx={{
                  '& > :not(style)': { marginLeft: '8px' },
                }}
              >
                <Btn
                  Text="Limpar Filtro"
                  BtnType="reset"
                  Type="regular"
                  Variant="outlined"
                  OnClick={ClearFields}
                  StartIcon={<FilterListOffIcon />}
                />
                <Btn
                  Text="Pesquisar"
                  BtnType="submit"
                  Type="primary"
                  Variant="contained"
                  StartIcon={<SearchIcon />}
                />
              </Grid>
            </Grid>
          </Form>
        </div>
        <div className="table-filtro">
          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            <Grid item xs={8}>
              <h3 id="table-title">Contatos ({Count})</h3>
            </Grid>
            <Grid item xs={4} sx={{ textAlign: 'right' }}>
              <Btn
                BtnType="button"
                Variant="contained"
                Type="primary"
                Text="Adicionar Contato"
                StartIcon={<AddIcon />}
                OnClick={() => {
                  navigate('/contato-marketing');
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            <Table>
              <TableHead>
                <Trow>
                  <Th className="CenterText">Id</Th>
                  <Th className="LeftText">Avatar</Th>
                  <Th className="LeftText">Nome</Th>
                  <Th className="CenterText">Telefone</Th>
                  <Th />
                  <Th className="CenterText">Status</Th>
                  <Th className="CenterText">Ações</Th>
                </Trow>
              </TableHead>
              {Contatos.length ? (
                <TableBody>
                  {Contatos.map((item: ContatosMarketingApi) => {
                    return (
                      <Tr key={item.id}>
                        <Td className="CenterText">{item.id}</Td>
                        <Td className="LeftText">
                          <Avatar
                            alt={item.nome}
                            src={`${process.env.REACT_APP_ANEXO_URL}${item.anexo}`}
                          />
                        </Td>
                        <Td className="LeftText">{item.nome}</Td>
                        <Td className="CenterText">{item.telefone}</Td>
                        <Td>
                          <IconButton
                            onClick={() => {
                              LinkWhatsApp(item.telefone);
                            }}
                          >
                            <WhatsAppIcon style={{ color: '#0F6EFE' }} />
                          </IconButton>
                        </Td>
                        <Td className="CenterText">
                          {handleTagStatus(item.status)}
                        </Td>
                        <Td className="CenterText">
                          <ActionButton>
                            <MenuItem
                              onClick={() => {
                                navigate(`/contato-marketing/${item.id}`);
                              }}
                            >
                              Alterar
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                handleClickOpenChangeStatus(
                                  item.id,
                                  item.status,
                                );
                              }}
                            >
                              {item.status ? 'Inativar' : 'Ativar'}
                            </MenuItem>
                          </ActionButton>
                        </Td>
                      </Tr>
                    );
                  })}
                </TableBody>
              ) : (
                ''
              )}
            </Table>
          </Grid>
          {Contatos.length ? (
            <Grid container spacing={1} sx={{ marginTop: '8px' }}>
              <Grid item xs={12} sx={{ textAlign: 'left' }}>
                <HvnPagination
                  Count={Paginas}
                  PaginationProp={{
                    onChange: (e: any) => {
                      SetPagina(Number(e.target.textContent));
                    },
                    defaultPage: Pagina,
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              {Pesquisando ? (
                <Grid container spacing={1} sx={{ marginTop: '16px' }}>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  spacing={1}
                  className="notFound"
                  sx={{ marginTop: '16px' }}
                >
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <HighlightOffIcon sx={{ fontSize: 54, color: '#C7CDD6' }} />
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: 'center' }}>
                    <h2>Nenhum resultado encontrado</h2>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </div>
      </Page>
    </Menu>
  );
};
