import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import Tooltip from '@mui/material/Tooltip';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactElement } from 'react';
import { IconRedeSocialButton } from './style';

interface IRedeSocialProps {
  Icon: ReactElement<any, any>;
  Link: string;
  Title: string;
}

export const RedeSocialButton = ({ Icon, Link, Title }: IRedeSocialProps) => {
  return (
    <Tooltip title={Title}>
      <IconRedeSocialButton
        onClick={() => {
          window.open(Link, '_blank');
        }}
      >
        {Icon}
      </IconRedeSocialButton>
    </Tooltip>
  );
};
