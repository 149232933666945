/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-return-assign */
/* eslint-disable sonarjs/no-identical-expressions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable sonarjs/no-duplicate-string */
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useCallback, useEffect, useRef, useState } from 'react';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import PinterestIcon from '@mui/icons-material/Pinterest';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import { AcesseAquiButton } from '../../Core/Components/AcesseAquiButton';
import { ExternalPost } from '../../Core/Types/Type';
import { API } from '../../Core/services/config-https';
import { InfinityScroll } from '../../Core/Components/InfinityScroll';
import './index.scss';
import { RedeSocialButton } from '../../Core/Components/RedeSocialButton';
import { TikTokIcon } from '../../Core/assets/LogoTikTok';
import { XIcon } from '../../Core/assets/LogoX';

/* eslint-disable react/function-component-definition */
export const Home = () => {
  const [Posts, SetPosts] = useState<Array<ExternalPost>>([]);
  const [EndPosts, SetEndPosts] = useState<boolean>(false);
  const [Pesquisando, SetPesquisando] = useState<boolean>(true);
  const [Page, SetPage] = useState<number>(1);

  const Render = useRef(false);

  const handleGetImages = useCallback(() => {
    SetPesquisando(true);
    console.log('Pesquisando....');
    API.get('/Post/External', {
      params: {
        Page,
        TotalRegisterPage: 6,
      },
    })
      .then(response => {
        const { data: Data } = response;
        if (Data.items.length > 0) {
          console.log(Data);
          Data.items.map((item: ExternalPost) => {
            return SetPosts(old => [...old, item]);
          });
          SetPesquisando(false);
        } else {
          SetEndPosts(true);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }, [Page]);

  const handleFeatchMore = () => {
    SetPage(Page + 1);
    handleGetImages();
  };

  useEffect(() => {
    if (!Render.current) {
      Render.current = true;
      handleFeatchMore();
    }
  }, []);

  return (
    <>
      <>
        <AppBar
          style={{
            backgroundColor: '#0040FF',
            color: '#fff',
          }}
        >
          <Toolbar>
            <h3>@havanoficial</h3>
          </Toolbar>
        </AppBar>
        <Toolbar />
      </>
      <Grid container spacing={2} sx={{ marginTop: '16px' }}>
        <Grid item xs={12} className="Center">
          <AcesseAquiButton
            title="Acesse o site"
            text="Acesse o site"
            link="https://www.havan.com.br/?utm_source=instagram&utm_medium=bio"
          />
        </Grid>
        <Grid item xs={12} className="Center">
          <AcesseAquiButton
            title="Baixe o App"
            text="Baixe o App"
            link="https://www.havan.com.br/havan-app/?utm_source=instagram&utm_medium=bio"
          />
        </Grid>
        <Grid item xs={12} className="Center">
          <AcesseAquiButton
            title="Horário de Lojas"
            text="Horário de Lojas"
            link="https://portalcliente.havan.com.br/nossas-lojas/?utm_source=instagram&utm_medium=bio"
          />
        </Grid>
        <Grid item xs={12} className="Center">
          <AcesseAquiButton
            title="Blog Havan"
            text="Blog Havan"
            link="https://blog.havan.com.br/?utm_source=instagram&utm_medium=bio"
          />
        </Grid>
        <Grid item xs={12} className="Center">
          <AcesseAquiButton
            title="Trabalhe conosco"
            text="Trabalhe conosco"
            link="https://cliente.havan.com.br/Portal/Institucional/TrabalheConosco?utm_source=instagram&utm_medium=bio"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className="Center"
          sx={{
            '& > :not(style)': {
              marginLeft: '8px',
              marginRight: '8px',
              marginTop: '16px',
            },
          }}
        >
          <RedeSocialButton
            Link="https://www.youtube.com/lojashavan"
            Title="YouTube"
            Icon={<YouTubeIcon />}
          />
          <RedeSocialButton
            Link="https://www.instagram.com/havanoficial/"
            Title="Instagram"
            Icon={<InstagramIcon />}
          />
          <RedeSocialButton
            Link="https://twitter.com/havanoficial"
            Title="Twitter"
            Icon={<XIcon />}
          />
          <RedeSocialButton
            Link="https://www.facebook.com/Havanoficial"
            Title="Facebook"
            Icon={<FacebookIcon />}
          />
          <RedeSocialButton
            Link="https://www.tiktok.com/@havanoficial"
            Title="TikTok"
            Icon={<TikTokIcon />}
          />
          <RedeSocialButton
            Link="https://www.linkedin.com/company/havan/"
            Title="LinkedIn"
            Icon={<LinkedInIcon />}
          />
          <RedeSocialButton
            Link="https://br.pinterest.com/lojashavan/"
            Title="Pinterest"
            Icon={<PinterestIcon />}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '24px' }}>
        <Grid
          item
          xs={3}
          style={{
            backgroundColor: '#0040FF',
            height: '24px',
            marginTop: '20px',
          }}
        />
        <Grid item xs={7}>
          <p>Confira nossas promoções</p>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{ marginTop: '24px', marginBottom: '200px' }}
      >
        <ImageList
          sx={{ width: '100%', padding: '0 8%', marginTop: '32px' }}
          variant="quilted"
          cols={2}
        >
          {Posts.map((item: ExternalPost) => {
            return (
              <ImageListItem
                key={item.id}
                sx={{
                  padding: '0 4%',
                  cursor: item.urlPromocao == null ? '' : 'pointer',
                }}
                title={item.nome}
                onClick={() => {
                  item.urlPromocao == null
                    ? ''
                    : window.open(item.urlPromocao, '_blank');
                }}
              >
                <img
                  src={`${process.env.REACT_APP_ANEXO_URL}${item.nomeBlob}?h=500&fit=crop&auto=format`}
                  srcSet={`${process.env.REACT_APP_ANEXO_URL}${item.nomeBlob}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.nome}
                  loading="lazy"
                />
              </ImageListItem>
            );
          })}
        </ImageList>
        {EndPosts ? (
          <>
            <Grid item xs={12} className="Center" sx={{ marginTop: '50px' }}>
              <h3>As Ofertas não param por aqui!</h3>
            </Grid>
            <Grid item xs={12} className="Center" sx={{ marginTop: '50px' }}>
              <AcesseAquiButton
                title="Confira Agora"
                text="Confira Agora"
                link="https://www.havan.com.br"
              />
            </Grid>
          </>
        ) : (
          <>
            {Pesquisando ? (
              <Grid item xs={12} className="Center" sx={{ marginTop: '50px' }}>
                <CircularProgress style={{ color: '#0040FF' }} />
              </Grid>
            ) : (
              <>
                <InfinityScroll
                  fetchmore={() => {
                    handleFeatchMore();
                  }}
                />
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};
