import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

export const IconRedeSocialButton = styled(IconButton)`
  border: 2px solid #0040ff !important;
  color: white !important;
  background: #0040ff !important;
  width: 48px;
  height: 48px;
`;
