import styled, { css } from 'styled-components';

interface LineProps {
  Blue?: boolean;
}

const ColorBlue = css`
  rgb(206, 226, 255);
`;

const ColorDefault = css`
  rgba(33, 33, 33, 0.08);
`;

export const Line = styled.hr<LineProps>`
  background-color: ${props => (props.Blue ? ColorBlue : ColorDefault)};
  height: 2px !important;
  margin-top: 3px;
`;
