import { useEffect, useRef } from 'react';

interface IInfinityScroll {
  fetchmore: () => void;
}

export const InfinityScroll = ({ fetchmore }: IInfinityScroll) => {
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        observer.disconnect();
        fetchmore();
      }
    }, options);
    observer.observe(contentRef.current as HTMLDivElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return <div ref={contentRef} style={{ bottom: 0 }} />;
};
