import { SvgIcon } from '@mui/material';

export const TikTokIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="22"
        height="21"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.4375 0.0166667C10.5292 0 11.6125 0.00833333 12.6958 0C12.7625 1.275 13.2208 2.575 14.1542 3.475C15.0875 4.4 16.4042 4.825 17.6875 4.96667V8.325C16.4875 8.28333 15.2792 8.03333 14.1875 7.51667C13.7125 7.3 13.2708 7.025 12.8375 6.74167C12.8292 9.175 12.8458 11.6083 12.8208 14.0333C12.7542 15.2 12.3708 16.3583 11.6958 17.3167C10.6042 18.9167 8.7125 19.9583 6.77083 19.9917C5.57917 20.0583 4.3875 19.7333 3.37083 19.1333C1.6875 18.1417 0.504167 16.325 0.329167 14.375C0.3125 13.9583 0.304167 13.5417 0.320833 13.1333C0.470833 11.55 1.25417 10.0333 2.47083 9C3.85417 7.8 5.7875 7.225 7.59583 7.56667C7.6125 8.8 7.5625 10.0333 7.5625 11.2667C6.7375 11 5.77083 11.075 5.04583 11.575C4.52083 11.9167 4.12083 12.4417 3.9125 13.0333C3.7375 13.4583 3.7875 13.925 3.79583 14.375C3.99583 15.7417 5.3125 16.8917 6.7125 16.7667C7.64583 16.7583 8.5375 16.2167 9.02083 15.425C9.17917 15.15 9.35417 14.8667 9.3625 14.5417C9.44583 13.05 9.4125 11.5667 9.42083 10.075C9.42917 6.71667 9.4125 3.36667 9.4375 0.0166667Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
