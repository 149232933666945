import { TagElement } from './styled';

interface TagProps {
  Name: string;
  BgColor: string;
  Color?: string;
}

export const Tag = ({ Name, BgColor, Color }: TagProps) => {
  return (
    <TagElement bgcolor={BgColor} color={Color || '#000'}>
      {Name}
    </TagElement>
  );
};
