/* eslint-disable react/button-has-type */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AttachmentIcon from '@mui/icons-material/Attachment';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CircularProgressbar } from 'react-circular-progressbar';
import ImageIcon from '@mui/icons-material/Image';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import {
  FileActions,
  FileData,
  FileInfo,
  FileName,
  FileType,
  LinkFileAction,
  ListActions,
  ListContainer,
  Preview,
  ProgressContainer,
} from './style';
import './index.scss';
import 'react-circular-progressbar/dist/styles.css';
import { DialogHvn } from '../../Dialog';
import { ImgPreview } from '../Preview';

interface FileProps {
  id: string;
  name: string;
  preview: string;
  downloadURL: string;
  url: string;
  type: string;
  progress: number;
  upload: boolean;
  erro: boolean;
}

interface props {
  files: Array<FileProps>;
  onDelete: (id: any) => void;
}

export const List = (Props: props) => {
  const { files: uploadedFiles } = Props;
  const [ModalPreview, setModalPreview] = useState(false);
  const [UrlPreview, setUrlPreview] = useState<string>('');

  const handleClickOpenModalPreview = (UrlImg: string) => {
    setUrlPreview(UrlImg);
    setModalPreview(true);
  };

  const handleCloseModalPreview = () => {
    setModalPreview(false);
  };

  return (
    <ListContainer>
      <DialogHvn
        onClose={handleCloseModalPreview}
        open={ModalPreview}
        tile="Preview"
        icon={<ImageIcon />}
        size="xl"
        content={<ImgPreview url={UrlPreview} />}
      />
      {uploadedFiles.map(file => {
        return (
          <FileInfo
            key={file.id}
            className={
              file.upload ? 'UploadSucess' : '' && file.erro ? 'UploadErro' : ''
            }
          >
            <input className="anexos-data" type="hidden" value={file.id} />
            <button
              type="button"
              style={{ outline: 0, border: 0 }}
              onClick={() => {
                handleClickOpenModalPreview(file.url);
              }}
            >
              <Preview src={file.preview} />
            </button>
            <FileData>
              <FileName>{file.name}</FileName>
              <FileType>{file.type}</FileType>
            </FileData>
            <FileActions>
              {file.progress === 100 || file.upload ? (
                <ListActions>
                  <IconButton
                    onClick={() => {
                      window.open(file.url, '_blank');
                    }}
                  >
                    <AttachmentIcon sx={{ color: '#1475eb' }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      Props.onDelete(file.id);
                    }}
                  >
                    <HighlightOffIcon sx={{ color: '#ff0004' }} />
                  </IconButton>
                </ListActions>
              ) : (
                <>
                  {file.erro ? (
                    <></>
                  ) : (
                    <ProgressContainer>
                      <CircularProgressbar
                        value={file.progress}
                        maxValue={100}
                      />
                    </ProgressContainer>
                  )}
                </>
              )}
            </FileActions>
          </FileInfo>
        );
      })}
    </ListContainer>
  );
};
