/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/destructuring-assignment */

import { Line } from '../Line';
import { LinkDisable, LinkEnable } from './style';
import { BreadCrumbsLinks } from './types';

interface BreadCrumbsProps {
  Links: BreadCrumbsLinks;
}

// eslint-disable-next-line react/function-component-definition
export const BreadCrumbs = ({ Links }: BreadCrumbsProps) => {
  const Mount = (Data: BreadCrumbsLinks) => {
    return Data.Pass.map(d => {
      return <LinkDisable href={d.Link}>{d.Title} |</LinkDisable>;
    });
  };

  return (
    <>
      {Mount(Links)}
      <LinkEnable>{Links.CurrentTitle}</LinkEnable>
      <Line />
    </>
  );
};
