import axios from 'axios';

export const API = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}`,
  timeout: 50000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const ClaimAPI = axios.create({
  baseURL: `${process.env.REACT_APP_CLAIM}`,
  timeout: 50000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

export const ENDPOINTS = {};

export const REDIRECT_LOGIN = `${process.env.REACT_APP_URL_LOGIN}=${process.env.REACT_APP_WEB_INTERNAL}/&RedirectUrl=${process.env.REACT_APP_WEB_INTERNAL}`;
