import styled from 'styled-components';

export const LinkDisable = styled.a`
  font-family: 'Poppins';
  color: #212529;
  font-size: 14px;
  font-style: normal;
  margin-right: 8px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: #212529;
    text-decoration: underline;
  }
`;

export const LinkEnable = styled.a`
  font-family: 'Poppins';
  color: #0d5ed8;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
`;
