import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactNode, useState } from 'react';
import styled from 'styled-components';
import { Btn } from '../Button';
import { Line } from '../Line';

interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  content: ReactNode;
  tile: string;
  icon?:
    | string
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined;
}

const HvnDialog = styled(Dialog)`
  & .MuiPaper-root {
    border-radius: 16px;
    padding: 16px;
    font-family: 'Poppins';
  }
`;

export const DialogHvn = ({
  onClose,
  open,
  size,
  content,
  icon,
  tile,
}: SimpleDialogProps) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <HvnDialog
      maxWidth={size || 'md'}
      fullWidth
      scroll="body"
      onClose={handleClose}
      open={open}
    >
      <DialogTitle fontFamily="Poppins">
        {icon} {tile}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 32,
              top: 25,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        ) : null}
        <Line Blue />
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
    </HvnDialog>
  );
};
