import { Avatar, CircularProgress, Divider, Grid } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import parse from 'html-react-parser';
import LogoMarketing from '../../Core/assets/LogoHavanMarketing.svg';
import LogoWhatsApp from '../../Core/assets/WhatsAppLogo.svg';
import { API } from '../../Core/services/config-https';
import { ExternalContatoMarketing } from '../../Core/Types/Type';
import './mkt.scss';

export const Marketing = () => {
  const [Contatos, SetContatos] = useState<Array<ExternalContatoMarketing>>([]);
  const [Pesquisando, SetPesquisando] = useState<boolean>(true);
  const Render = useRef<boolean>(false);
  const Count = useRef<number>(0);

  const handlePesquisa = useCallback(async () => {
    await API.get('/ContatosMarketing/External', {
      params: {
        Page: 1,
        TotalRegisterPage: 1000,
      },
    }).then(response => {
      const { data: Data } = response;
      Count.current = Data.count;
      SetContatos(Data.items);
      SetPesquisando(false);
    });
  }, []);

  useEffect(() => {
    const body = document.getElementById('b');
    if (body) {
      body.style.backgroundColor = '#0040FF';
    }

    if (!Render.current) {
      Render.current = true;
      handlePesquisa();
    }
  }, []);

  const LinkWhatsApp = (phone: string): string => {
    const WhatsAppNumber = phone
      .replaceAll(' ', '')
      .replace('-', '')
      .replace('(', '')
      .replace(')', '');

    return `https://api.whatsapp.com/send?1=pt_BR&phone=55${WhatsAppNumber}`;
  };

  return (
    <div className="mkt">
      <Grid container spacing={2} sx={{ marginTop: '5px' }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <img src={LogoMarketing} alt="Logo Marketing" />
          <p className="LogoSubTitle">
            Clique no ícone do WhatsApp e fale conosco:
          </p>
        </Grid>
      </Grid>
      <div className="mkt-feeds">
        {Pesquisando ? (
          <Grid container spacing={2} sx={{ marginTop: '16px' }}>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <CircularProgress sx={{ color: '#FFFF' }} />
            </Grid>
          </Grid>
        ) : (
          <>
            {Contatos.map((item: ExternalContatoMarketing, index: number) => {
              return (
                <Grid
                  container
                  spacing={2}
                  sx={{ marginTop: '16px' }}
                  key={item.id}
                  className="SingleMKTContact"
                >
                  <Grid item xs={1}>
                    <Grid item xs={12}>
                      <a href={LinkWhatsApp(item.telefone)}>
                        <Avatar
                          alt="Eduardo"
                          className="AvatarMKT"
                          src={`${process.env.REACT_APP_ANEXO_URL}${item.fotoPerfil}`}
                        />
                      </a>
                    </Grid>
                  </Grid>
                  <Grid item xs={11}>
                    <Grid item xs={12} className="TitleName">
                      <a href={LinkWhatsApp(item.telefone)}>
                        <img
                          className="WhatsAppLogo"
                          src={LogoWhatsApp}
                          alt="whatsapp"
                        />
                        <h3>{item.nome}</h3>
                      </a>
                    </Grid>
                    <Grid item xs={12} className="DescriptionMKT">
                      <p>{parse(item.texto)}</p>
                    </Grid>
                  </Grid>
                  {index + 1 < Count.current ? (
                    <Grid item xs={12} className="Divader">
                      <Divider sx={{ backgroundColor: 'white' }} />
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
