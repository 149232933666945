/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */

import { Toolbar } from '@mui/material';
import React, { ReactElement } from 'react';
import { BlankBoard } from '../BlankBoard';
import { BreadCrumbs } from '../BreadCrumbs';
import { Title } from './style';

declare type Links = {
  Title: string;
  Link: string;
};

declare type BreadCrumbsLinks = {
  CurrentTitle: string;
  Pass: Array<Links>;
};

declare type PageProps = {
  title: string;
  BreadCrumb?: BreadCrumbsLinks;
  children: React.ReactNode;
};

export const Page: React.FC<PageProps> = ({ title, BreadCrumb, children }) => {
  return (
    <>
      <Title>{title}</Title>
      <BlankBoard>
        {BreadCrumb ? (
          <>
            <BreadCrumbs Links={BreadCrumb} />
            {children}
          </>
        ) : (
          <>{children}</>
        )}
      </BlankBoard>
    </>
  );
};
