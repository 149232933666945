/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSnackbar } from 'notistack';
import { useUser } from 'oidc-havan-ts';
import { Drop } from './DropFiles';
import { List } from './ListFiles';
import { API } from '../../services/config-https';
import { useAuth } from '../../Context/useAuth';

interface FileProps {
  file: [];
  id: string;
  name: string;
  preview: string;
  downloadURL: string;
  url: string;
  type: string;
  progress: number;
  upload: boolean;
  erro: boolean;
}

interface UploadProps {
  Files: Array<FileProps>;
}

export const Upload = (Props: UploadProps) => {
  const [uploadedFiles, SetuploadedFiles] = useState<Array<FileProps>>([]);
  const [render, setRender] = useState(false);
  const { accessToken } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = (files: any) => {
    console.log(uploadedFiles.length);
    if (uploadedFiles.length < 1) {
      const filesup = files.map((file: any) => ({
        file,
        id: uuidv4(),
        name: file.name,
        type: file.type,
        preview: URL.createObjectURL(file),
        downloadURL: '',
        url: '',
        progress: 0,
        upload: false,
        erro: false,
      }));
      filesup.map((f: any) => {
        return SetuploadedFiles(old => [...old, f]);
      });
      setTimeout(() => {
        filesup.forEach((item: FileProps) => {
          ProcessUpload(item);
        });
      }, 2000);
    } else {
      enqueueSnackbar('Apenas 1 anexo é permitido', { variant: 'error' });
    }
  };

  // useEffect(() => {
  //   // console.log(uploadedFiles);
  //   console.log('Files ============================');
  //   console.log(Props.Files);
  // }, [uploadedFiles]);

  const SetFileData = (uid: any, data: FileProps) => {
    SetuploadedFiles(a =>
      a.map(i => {
        return uid === i.id ? { ...i, ...data } : i;
      }),
    );
  };

  const ProcessUpload = (item: FileProps) => {
    const data = new FormData();

    data.append('File', item.file as any);

    API.post('/Anexo', data, {
      headers: { Authorization: accessToken },
      onUploadProgress: (e: any) => {
        const progress = Number(Math.round((e.loaded * 100) / e.total));
        SetFileData(item.id, {
          ...item,
          progress,
        });
      },
    })
      .then((response: any) => {
        const { model: Model } = response.data;
        SetFileData(item.id, {
          ...item,
          upload: true,
          id: Model.id,
          downloadURL: '',
          url: `${process.env.REACT_APP_ANEXO_URL}${Model.nomeBlob}`,
          preview: `${process.env.REACT_APP_ANEXO_URL}${Model.nomeBlob}`,
        });
      })
      .catch(() => {
        enqueueSnackbar('Erro ao enviar o anexo', { variant: 'error' });
        SetFileData(item.id, {
          ...item,
          erro: true,
        });
      });
  };

  const HandleDelete = (id: any) => {
    SetuploadedFiles(d => d.filter(f => f.id !== id));
  };

  // const HandleDownload = async (id: any) => {
  //   await API.get('/Anexo/Download', {
  //     headers: { Authorization: `Bearer ${auth.user?.token}` },
  //     params: { Id: id },
  //   }).then((response: any) => {
  //     console.log('============================Download');
  //     console.log(response);
  //     const ub = URL.createObjectURL(response.data);
  //     console.log(ub);
  //   });
  // };

  useEffect(() => {
    console.log('====================================================');
    console.log(Props.Files);
    if (Props.Files) {
      Props.Files.map((file: FileProps) => {
        return SetuploadedFiles([file]);
      });
    }
    // eslint-disable-next-line react/destructuring-assignment
  }, [Props.Files]);

  return (
    <>
      <Drop onUpload={handleUpload} />
      <List files={uploadedFiles} onDelete={HandleDelete} />
    </>
  );
};
