/* eslint-disable react/jsx-no-useless-fragment */
import Grid from '@mui/material/Grid';
import GroupIcon from '@mui/icons-material/Group';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LaunchIcon from '@mui/icons-material/Launch';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import { Grow } from '@mui/material';
import { useUser } from 'oidc-havan-ts';
import { Btn } from '../../Core/Components/Button';
import { Menu } from '../../Core/Components/Menu';
import { Page } from '../../Core/Components/Page';
import { PostsApi } from '../../Core/Types/Type';
import { API } from '../../Core/services/config-https';
import { useValidatePermition } from '../../Core/hooks/useValidatePermition';

export const Admin = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [Post, SetPost] = useState<PostsApi>();
  const [PostPermition, SetPostPermition] = useState<boolean>();
  const [MktPermition, SetMktPermition] = useState<boolean>();
  const [Pesquisando, SetPesquisando] = useState<boolean>(true);
  const { accessToken, identity } = useUser();
  const Render = useRef(false);

  const handlePesquisa = useCallback(async () => {
    if (!Render.current) {
      Render.current = true;
    }

    SetPesquisando(true);

    await API.get('/Post', {
      headers: { Authorization: accessToken },
      params: {
        TotalRegisterPage: 1,
        Page: 1,
        Status: true,
      },
    })
      .then(response => {
        const { data: Data } = response;
        SetPost(Data.items[0]);
        setTimeout(() => {
          SetPesquisando(false);
        }, 2000);
      })
      .catch((error: Error) => {
        enqueueSnackbar('Ocorreu um erro ao Pesquisar', { variant: 'error' });
        SetPesquisando(false);
        console.log(error);
      });
  }, [accessToken, enqueueSnackbar]);

  useEffect(() => {
    if (accessToken !== null) handlePesquisa();
    if (identity?.codigo) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_POSTS').then(v =>
        SetPostPermition(v),
      );
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useValidatePermition(identity?.codigo, 'FN_CONTATOS').then(v =>
        SetMktPermition(v),
      );
    }
  }, [accessToken, handlePesquisa, identity]);

  return (
    <Menu>
      <Page title="Administração">
        <Grid container spacing={2} sx={{ marginTop: '5px' }}>
          <Grid item xs={12}>
            <h3>{`Bem-vindo(a) ${identity?.nome}`}</h3>
          </Grid>
          <Grid
            item
            xs={5}
            spacing={2}
            sx={{ '& > :not(style)': { marginTop: '16px' } }}
          >
            {PostPermition ? (
              <Grid item xs={12} sx={{ marginTop: '20px' }}>
                <Btn
                  BtnType="button"
                  Variant="contained"
                  Type="primary"
                  Size="large"
                  Width="100%"
                  StartIcon={<DynamicFeedIcon />}
                  Text="Posts"
                  OnClick={() => {
                    navigate('/posts');
                  }}
                />
              </Grid>
            ) : (
              ''
            )}
            {MktPermition ? (
              <Grid item xs={12}>
                <Btn
                  BtnType="button"
                  Variant="contained"
                  Type="primary"
                  Size="large"
                  Width="100%"
                  StartIcon={<GroupIcon />}
                  Text="Contatos Marketing"
                  OnClick={() => {
                    navigate('/contatos-marketing');
                  }}
                />
              </Grid>
            ) : (
              ''
            )}
            <Grid item xs={12} sx={{ marginTop: '20px' }}>
              <Btn
                BtnType="button"
                Variant="outlined"
                Type="secundary"
                Size="large"
                Width="100%"
                StartIcon={<LaunchIcon />}
                Text="Abrir Acesse Aqui"
                OnClick={() => {
                  window.open('/acesse-aqui', '_blank');
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Btn
                BtnType="button"
                Variant="outlined"
                Type="secundary"
                Size="large"
                Width="100%"
                StartIcon={<LaunchIcon />}
                Text="Abrir Contatos Marketing"
                OnClick={() => {
                  window.open('/contatos', '_blank');
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={7} spacing={2}>
            <Grid item xs={12}>
              <h3>Último Post Cadastrado</h3>
            </Grid>
            <Grid item xs={12}>
              {Pesquisando ? (
                <Grid
                  item
                  xs={12}
                  sx={{ textAlign: 'center', marginTop: '15%' }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <>
                  {Post !== undefined ? (
                    <Grow in={Post !== undefined}>
                      <div className="last-post">
                        <Grid container spacing={1} sx={{ marginTop: '16px' }}>
                          <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <a
                              href={Post.urlPromocao}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={`${process.env.REACT_APP_ANEXO_URL}${Post.anexo}`}
                                alt={Post.descricao}
                                style={{ height: '340px' }}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      </div>
                    </Grow>
                  ) : (
                    <Grid container spacing={1} sx={{ marginTop: '16px' }}>
                      <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'center', color: '#bdbdbd' }}
                      >
                        <HighlightOffIcon sx={{ fontSize: '42px' }} />
                        <h3>Sem nenhum post cadastrado</h3>
                      </Grid>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </Menu>
  );
};
